@import "../../__variables.scss";

#room {
  .link {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: $text-medium;
    border-bottom: 2px solid $lightgray;
    background-color: $white;
    .share-text {
      font-size: $text-small;
      color: $text-hint;
    }
  }

  .name-description {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: $text-medium;
    border-bottom: 2px solid $lightgray;
    background-color: $white;
    .name {
      font-size: $text-large;
    }
  }

  .change-settings {
    font-size: $text-small;
    cursor: pointer;
    color: $text-hint;
    &:hover {
      text-decoration: underline;
    }
  }

  .loading {
    text-align: center;
    margin-top: 10vh;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    .fileList-wrapper,
    .dropzone-wrapper {
      height: calc(100vh - 220px);
      width: 50%;
    }

    .fileList-wrapper {
      overflow-y: scroll;
    }
  }

  @media (max-width: $breakpoint) {
    .flex {
      display: block;
      height: auto;
      .fileList-wrapper,
      .dropzone-wrapper {
        width: 100%;
      }
      .dropzone-wrapper {
        height: auto;
      }
    }
  }
}
