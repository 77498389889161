@import "../../../../__variables.scss";

.upload-filelist-lifetime {
  border-top: 2px solid #f2f2f2;
  padding: 10px;
  display: flex;
  .label {
    width: 50%;
    font-weight: bold;
  }
  select {
    width: 50%;
    border: none;
    text-align: center;
    outline: none;
    font-size: $text-medium;
    padding: 0px;
  }

  @media (max-width: $breakpoint) {
    display: block;
    .label, select {
      width: 100%;
    }
  }
}
