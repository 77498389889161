@import "../../__variables.scss";

#account {
  padding: 40px 0px;
  
  .headline {
    text-align: center;
    font-size: $text-x-large;
    color: $text-headline;
    margin-bottom: 40px;
  }

  .content-wrapper {
    background-color: $lightgray;
    padding: 30px;
    border-radius: $default-border-radius;
  }

  .area-wrapper {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: thin solid $gray;
    &:last-child {
      margin-bottom: 0px;
      border: none;
    }
  }

  @media (max-width: $breakpoint) {

  }
}
