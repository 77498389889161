@import "../../__variables.scss";

#pricing {

  .headline {
    text-align: center;
    font-size: $text-x-large;
    padding: 50px 0px;
    color: $red;
  }

  .planlist {
    background-color: $white;
    border-radius: $default-border-radius;
    border: thin solid $lightgray;
    padding-top: 40px;
    .row {
      display: flex;
      .column {
        flex: 1 1 0px;
        text-align: center;
        &.highlight {
          color: $red;
          font-size: $text-large;
        }
        &.price {
          font-weight: bold;
        }
        .hint {
          font-size: $text-small;
          color: $text-hint;
        }
      }
    }
    .select {
      background-color: $red;
      color: $white;
      padding: 7px;
      width: 100%;
      display: inline-block;
      border-bottom-left-radius: $default-border-radius;
      border-bottom-right-radius: $default-border-radius;
      cursor: pointer;
      text-align: center;
      font-size: $text-large;
      margin-top: 30px;
    }
    .attribute {
      text-align: center;
      border-top: thin solid $lightgray;
      border-bottom: thin solid $lightgray;
      padding: 15px 0px;
      margin: 15px 0px;
      .title {
        font-size: $text-medium;
        font-weight: bold;
      }
      .hint {
        font-size: $text-small;
        color: $text-hint;
      }
    }
  }

  @media (max-width: $breakpoint) {
      
  }
}
