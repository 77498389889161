@import "../../../__variables.scss";

.chat,
.chat-minified {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.chat-minified {
  border-radius: 20px;
  background-color: $gray;
  .open-wrapper {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
}

.chat {
  background-color: $white;
  height: 100%;
  width: 100%;
  border: 2px solid $red;
  border-radius: $default-border-radius;
  width: 30vw;
  height: 40vh;

  .header {
    border-bottom: 2px solid $lightgray;
    padding: 5px;
    .title {
      color: $red;
      font-size: $text-medium;
    }
    .hint {
      color: $text-hint;
      font-size: $text-small;
    }
    .close {
      position: absolute;
      right: 7px;
      top: 5px;
      cursor: pointer;
      img {
        height: 15px;
      }
    }
  }

  .message-list {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 98px);
  }
}

@media (max-width: $breakpoint) {
  .chat,
  .chat-minified {
    position: fixed;
    bottom: 15px;
    right: 15px;
  }

  .chat-minified {
    border-radius: 20px;
    .open-wrapper {
      padding: 10px;
      img {
        height: 20px;
      }
    }
  }

  .chat {
    width: calc(100vw - 30px);
  height: calc(100vh - 90px);
  }
}
