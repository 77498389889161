@import "../../__variables.scss";

#home {
  .quickstart-wrapper {
    padding: 10vh 0px;
  }

  @media (max-width: $breakpoint) {
      
  }
}
