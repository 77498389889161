@import "../../../../__variables.scss";

.account-paymentplan-item {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .group {
    width: 50%;

    .label {
      font-weight: bold;
    }
  }

  .select {
    margin-top: 15px;
  }

  @media (max-width: $breakpoint) {
    display: block;
    .group {
      width: 100%;
    }
  }
}
