@import "../../../__variables.scss";

#navigation {
  .item {
    text-decoration: none;
    padding: 0px 20px;
    display: inline-block;
    color: $white;
    font-size: $text-large;
  }

  .mobile-toggle {
    display: none;
    line-height: 100%;
    img {
      height: 30px;
    }
  }

  @media (max-width: $breakpoint) {
    .item-list {
      z-index: 50;
      display: none;
      position: fixed;
      left: 0px;
      top: 60px;
      width: 100%;
      background-color: $white;
      box-shadow: $default-box-shadow;
      .item {
        display: block;
        color: $black;
        padding: 7.5px;
        border-bottom: thin solid $lightgray;
      }
      &.show {
        display: block;
      }
    }
    .mobile-toggle {
      display: block;
    }
  }
}
