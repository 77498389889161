@import "../../../../__variables.scss";

#registration-form {
  .form-wrapper {
    width: 100%;
    input {
      margin: 15px 0px;
    }
    button {
      display: block;
      margin-left: auto;
    }

    .error {
      text-align: center;
      color: $text-failure;
      margin-top: 25px;
    }
  }
}

@media (max-width: $breakpoint) {
  #registration {
    .form-wrapper {
      margin-top: 0px;
      width: 100%;
    }
  }
}
