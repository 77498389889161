@import "../../../../../__variables.scss";

.upload-filelist-limitation {
  border-bottom: 2px solid #f2f2f2;
  .error {
    text-align: center;
    color: $text-failure;
  }
  .warning {
    text-align: center;
    background-color: $orange;
    color: $white;
  }
}
