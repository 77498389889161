@import "../../__variables.scss";

.copylink {
  display: inline-block;
  cursor: pointer;
  .text {
    display: inline-block;
    font-size: $text-medium;
    line-height: 15px;
    &:hover {
      color: $red;
    }
  }

  .icon {
    img {
      height: 15px;
      margin-top: -5px;
      margin-left: 10px;
    }
  }
  .text,
  .icon {
    display: inline-block;
  }

  .mobile-button {
    font-size: $text-small;
    display: none;
  }

  @media (max-width: $breakpoint) {
    .icon, .text {
      display: none;
    }
    .mobile-button {
      display: block;
    }
  }
}
