@import "../../__variables.scss";

#imprint {
  .headline {
    text-transform: uppercase;
    color: $text-headline;
    font-size: 24px;
    letter-spacing: 6.8px;
    margin-top: 50px;
  }
  .subheadline {
    font-size: 15px;
    color: $text-headline;
    margin: 15px 0px;
  }
  p {
    margin: 15px 0px;
    color: $text;
    font-size: 15px;
  }
}

@media (max-width: $breakpoint) {
  #imprint {
  }
}
