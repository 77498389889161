@import "../../../__variables.scss";

#login {

  text-align: center;

  .headline {
    text-align: center;
    font-size: $text-x-large;
    color: $text-headline;
  }

  .form-wrapper {
    width: 40vw;
    margin-top: 10vh;
    display: inline-block;
    padding: 20px;
    background-color: $lightgray;
    border-radius: $default-border-radius;
    form {
      input {
        margin: 15px 0px;
      }
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .error {
      text-align: center;
      color: $text-failure;
      margin-top: 25px;
    }
  }
}

@media (max-width: $breakpoint) {
  #login {
      .form-wrapper {
        margin-top: 0px;
        width: 100%;
      }
      .image {
        display: none;
      }
  }
}
