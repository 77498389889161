@import "../../../__variables.scss";

#quick-start {
   .intro, .button-wrapper, .explain {
       text-align: center;
   }
   .intro {
       font-size: $text-x-large;
       color: $text;
   }
   .button-wrapper {
        margin-top: 50px;
       .button {
           font-size: $text-x-large;

       }
   }
   .explain {
       margin-top: 15px;
       font-size: $text-small;
       color: $text-hint;
       width: 300px;
       margin-left: auto;
       margin-right: auto;
   }
}