@import "../../../../__variables.scss";

.upload-filelist-file {
  border-bottom: 2px solid $lightgray;
  display: flex;
  padding: 10px 10px;
  align-items: center;
  flex-wrap: wrap;
  .gray {
    background-color: $bg-hint;
  }

  .attr {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .remove-wrapper, .size {
    width: 20%;
    text-align: right;
  }
  .name {
    width: 60%;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: $breakpoint) {
    padding: 10px 10px;
    .name {
      width: 100%;
    }
    .remove-wrapper, .size {
      width: 50%;
    }
    .size {
      text-align: left;
    }
    .attr {
      width: 100%;
      text-align: left;
      &.download-wrapper {
        text-align: center;
      }
    }
  }
}
