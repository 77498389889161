@import "../../__variables.scss";

#footer {
  background-color: $white;
  border-top: 2px solid $red;
  .banner {
    text-align: center;
    background-color: $bg-success;
    font-size: 40px;
    color: $text;
    padding: 15px;
    font-weight: bolder;
    img {
      height: 60px;
    }
  }
  .info {
    padding: 30px 0px;
    .container {
      display: flex;
      .block {
        width: 20%;
        flex: 1 1 0px;
        div {
          text-align: center;
          line-height: 2.2;
          font-size: 14px;
          color: $text;
          a {
            color: $text;
            text-decoration: none;
            &:hover {
              color: $text-link-hover;
            }
          }
        }
      }
    }
  }
  .copyright {
    background-color: $darkgray;
    padding: 20px 0px;
    .container {
      font-size: $text-small;
      display: flex;
      div {
        flex: 1 1 0px;
        text-align: center;
        color: $white;
        a {
          color: $white;
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #footer {
    .copyright {
      text-align: center !important;
      .container {
        display: block;
        div {
          margin-bottom: 10px;
        }
      }
    }
    .info {
      display: block;
      padding-top: 50px;
      .block {
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }
}
