@import "./__variables";
@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"),
    url(./assets/font/Comfortaa/Comfortaa-VariableFont_wght.ttf)
      format("truetype");
}
@font-face {
  font-family: "intro-thin";
  src: local("intro-thin"), url(./assets/font/Intro/thin.otf) format("opentype");
}
@font-face {
  font-family: "intro-regular";
  src: local("intro-regular"),
    url(./assets/font/Intro/regular.otf) format("opentype");
}
@font-face {
  font-family: "intro-bold";
  src: local("intro-bold"), url(./assets/font/Intro/bold.otf) format("opentype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "intro-regular", sans-serif;
  font-size: $text-medium;
  color: $text;
}

body {
  background-image: url("./assets/image/bg.jpg");
  background-size: 100% auto;
  background-repeat: repeat-y;
}

.button {
  border-radius: $default-border-radius;
  background-color: $bg-button;
  color: $text-button;
  display: inline-block;
  padding: 5px 10px;
  font-family: "intro-bold";
  cursor: pointer;
  border: none;
}

input {
  outline: none;
  &:active, &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: thin solid $black !important;
  }
}
