@import "../../../__variables.scss";

#account-paymentplan {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }

  .hint {
    color: $text-hint;
  }

  .billing-data-hint {
    font-weight: bold;
    margin: 10px 0px;
  }

  .plan-selection .dropdown button {
    background-color: $red;
    border: none;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
  }
}
