@import "../../../__variables.scss";

#account-invoice {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }
  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
  }
}
