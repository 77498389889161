@import "../../__variables.scss";

#header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
  height: 60px;

  transition: all 0.2s ease-out;
  background-color: $color-main;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    cursor: pointer;
    font-weight: bolder;
    font-size: 30px;
    color: $white;
    font-family: "intro-bold";
    img {
      max-height: 40px;
    }
  }

  .navigation-wrapper {
    position: absolute;
    right: 0px;
  }

  @media (max-width: $breakpoint) {
  }
}
