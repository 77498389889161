@import "../../../__variables.scss";

#registration {
  background-color: $lightgray;
  padding: 30px;
  margin-top: 100px;
  border-radius: $default-border-radius;
  .headline {
    text-align: center;
    font-size: $text-x-large;
    color: $text-headline;
  }

  .finish {
    text-align: center;
    .link {
      text-decoration: underline;
      cursor: pointer;
      color: $red;
    }
  }

  .steps {
    display: flex;
    margin: 30px 0px;
    .step {
      flex: 1 1 0px;
      text-align: center;
      border-bottom: 3px solid $gray;
      font-weight: bold;
      &.active {
        border-bottom: 3px solid $red;
      }
    }
  }
  
  @media (max-width: $breakpoint) {
    padding: 10px;
  }
}
