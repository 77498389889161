$superblack: #000000;
$black: #4B4B4D;
$lightblack: #4A4A4A;
$darkgray: rgb(75,75,77);
$white: #ffffff;
$almostwhite: #fbf9f9;
$blue: #0c3851;
$lightblue: #4AA7CB;
$lightgray: rgb(242,242,242);
$gray: #808080;
$red: rgb(204, 0, 0);
$orange: rgb(231, 158, 0);
$green: #21B692;

$color-main: $red;

$text: $black;
$text-invert: $white;
$text-link-hover: $lightblack;
$text-headline: $red;
$text-hint: $gray;
$text-success: $green;
$text-failure: $red;
$text-button: $white;

$text-x-small: 11px;
$text-small: 13px;
$text-medium: 15px;
$text-large: 18px;
$text-x-large: 21px;

$text-bold: 500;

$bg: $white;
$bg-button: $red;
$bg-success: $green;
$bg-copyright: $superblack;
$bg-hint: $lightgray;

$border: $gray;
$border-highlight: $green;
$border-hint: $lightgray;
$border-input: $gray;
$border-input-active: $lightblack;

$default-border-radius: 3px;

$default-box-shadow: 0 0 24px #ddd;

$default-zoom-in-scale: scale(1.005);

$default-transition-speed: 0.2s;

$breakpoint: 992px;