@import "../../../../__variables.scss";

.chat-input {
  border-top: 2px solid $lightgray;
  overflow: hidden;

  input {
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    padding: 5px;
    &:focus, &:active {
      border: none !important;
      outline: none;
    }
  }
  
  @media (max-width: $breakpoint) {
    
  }
}
