@import "../../../__variables.scss";

#signup-activation {
  .headline {
    text-align: center;
    font-size: $text-x-large;
    color: $text-headline;
    padding-bottom: 25px;
  }
  .flex-wrapper {
    display: flex;
    align-items: center;
    .confirmation {
        width: 60%;
        .headline {
          font-size: $text-large;
          color: $text-headline;
        }
      .subheadline {
        text-align: center;
      }
      .failure {
        .headline {
          color: $text-failure;
        }
      }
    }
    .image {
      width: 40%;
      padding: 15px;
      img {
          max-width: 100%;
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #signup-activation {
    .flex-wrapper {
        display: block;
        .form-wrapper {
            width: 100%;
        }
        .image {
            display: none;
        }
      }
  }
}
