@import "../../../../__variables.scss";

.drag-inactive {
  .content {
    text-align: center;
    .image {
      height: 20vh;
      img {
        max-height: 100%;
      }
    }
    .text {
      font-size: 2em;
      margin-top: 20px;
      color: $text;
    }
  }
}
