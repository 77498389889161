@import "../../../../__variables.scss";

#stripe {

  .button-wrapper {
    margin-top: 15px;
    text-align: right;
  }
  
  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
  }
}
