@import "../../../../__variables.scss";

.section {
  display: flex;
  padding: 5vw;
  background-color: rgba(255, 255, 255, 0.5);
  &.gray {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.invert {
    flex-direction: revert;
  }
  .content {
    width: 50%;
    .title {
      font-size: 30px;
      color: $red;
      font-family: "intro-bold";
    }
    .text {
      margin-top: 15px;
      font-size: $text-large;
    }
  }
  .image-wrapper {
    width: 50%;
    text-align: center;
    img {
      height: 300px;
    }
  }

  @media (max-width: $breakpoint) {
    display: block;
    .content,
    .image-wrapper {
      width: 100%;
    }
    .image-wrapper {
      img {
        width: 75%;
      }
    }
  }
}
