@import "../../../__variables.scss";

#account-payment-detail {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }
  .form-wrapper {
    width: 100%;
    input {
      margin: 15px 0px;
    }
    button {
      display: block;
      margin-left: auto;
    }

    .error {
      text-align: center;
      color: $text-failure;
      margin-top: 25px;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
