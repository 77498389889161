@import "../../../../__variables.scss";

.chat-message {
  border-bottom: 2px solid $lightgray;
  padding: 5px;

  .content {
    word-wrap: break-word;
  }

  .meta {
    display: block;
    text-align: right;
    border-top: 1px solid $lightgray;
    .valid-until {
      color: $gray;
      margin-right: 10px;
    }
    .remove {
      color: $red;
      cursor: pointer;
    }
    .valid-until, .remove {
      font-size: $text-small;
      display: inline-block;
    }
  }

  @media (max-width: $breakpoint) {
    
  }
}
