@import "../../../../__variables.scss";

.room-organizer-item {
  border-bottom: thin solid $lightgray;
  padding: 10px;
  display: flex;
  background-color: $white;
  .attribute {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 0px;
    text-align: center;
    &.name {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
    &.remove {
      img {
        cursor: pointer;
        height: 20px;
      }
    }
  }
  &:last-child {
    border-bottom: 0px;
  }

  @media (max-width: $breakpoint) {
  }
}
