@import "../../../../__variables.scss";

.filelist-file {
  border-bottom: 2px solid $lightgray;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  align-items: center;
  .gray {
    background-color: $bg-hint;
  }

  .attr {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .download-wrapper, .size, .createdOn {
    width: 20%;
  }
  .name {
    width: 35%;
  }
  .info {
    width: 5%;
    padding-bottom: 3px;
    text-align: right;
    img {
      height: 17px;
      cursor: pointer;
    }
  }

  .more-info {
    margin-top: 10px;
    display: flex;
    width: 100%;
    .attr {
      width: 50%;
      &.delete {
        text-align: right;
        color: $red;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .size, .createdOn {
    text-align: right;
  }

  @media (max-width: $breakpoint) {
    display: block;
    .attr {
      width: 100%;
      text-align: left;
      &.download-wrapper {
        text-align: center;
      }
    }
  }
}
