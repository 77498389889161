@import "../../../__variables.scss";

#account-delete {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }

  .hint {
    color: $text-hint;
    margin-bottom: 10px;
  }

  .sure-cta {
    color: $red;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .sure-no {
    background-color: $gray;
    margin-left: 10px;
  }

  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
  }
}
