@import "../../../__variables.scss";

.filelist {
  padding-top: 5px;
  padding-bottom: 30px;
  overflow-y: scroll;
  background-image: url("../../../assets/image/bg.jpg");
  background-size: 100% auto;
  background-repeat: repeat-y;
  height: 100%;

  .no-files-yet {
    font-size: $text-large;
    text-align: center;
    margin-top: 30vh;
    width: 40%;
    margin-left: 30%;
  }

  @media (max-width: $breakpoint) {
    height: auto;
    .no-files-yet {
      width: 80%;
      margin-left: 10%;
      margin-top: 5vh;
    }
  }
}
