@import "../../__variables.scss";

#data-protection {
  .headline {
    text-transform: uppercase;
    color: $text-headline;
    font-size: 24px;
    letter-spacing: 6.8px;
    margin-top: 50px;
  }
  .subheadline {
    font-size: 15px;
    color: $text-headline;
    margin: 15px 0px;
  }
  p {
    margin: 15px 0px;
    color: $text;
    font-size: 15px;
  }
  a {
    font-size: 15px;
    color: $text;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: $text-link-hover;
    }
  }
}

@media (max-width: $breakpoint) {
  #data-protection {
  }
}
