@import "../../../__variables.scss";

.upload-filelist {
  border-bottom: 2px solid $bg-hint;

  .no-files-selected {
    font-size: $text-large;
    text-align: center;
    margin-top: 40vh;
  }

  .list {
    max-height: 20vw;
    overflow-y: scroll;
  }

  .start-upload {
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    text-align: center;
  }

  .progress-wrapper {
    margin: 30px auto;
  }

  @media (max-width: $breakpoint) {
    .list {
      max-height: none;
    }
  }
}
