@import "../../../__variables.scss";

#no-such-room {
    width: 100%;
    .title {
        text-align: center;
        margin: 10vh auto;
        font-size: 50px;
    }
    
    .image {
        text-align: center;
        height: 30vh;
        img {
            height: 100%;
        }
    }

  @media (max-width: $breakpoint) {
  }
}
