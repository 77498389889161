@import "../../../__variables.scss";

.dropzone {
  height: 100%;
  cursor: pointer;
  .dragable {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightgray;
    width: 100%;
    height: 100%;
  }
  &:focus, &:active {
    outline: none;
  }
  @media (max-width: $breakpoint) {
    .dragable {
      padding: 30px;
    }
  }
}
