@import "../../../__variables.scss";

#account-volume {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }

  .hint {
    color: $text-hint;
    margin-bottom: 10px;
  }

  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
  }
}
