@import "../../../__variables.scss";

#room-infoedit {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  padding-top: 10vh;
  .filter {
    backdrop-filter: blur(10px);
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1001;
    top: 0px;
  }
  .modal-wrapper {
    z-index: 1002;
    position: absolute;
    width: 100%;
  }
  .form-label {
    margin-bottom: 0px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .remove-password-wrapper {
    text-align: right;
    .remove-password {
      color: $text-hint;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
