@import "../../../__variables.scss";

#roomOrganizer {
  
    .headline {
        font-size: $text-x-large;
        font-weight: bold;
    }

    .room-list {
        border: thin solid $lightgray;
        border-radius: $default-border-radius;
        overflow: hidden;
    }

  @media (max-width: $breakpoint) {
  }
}
