@import "../../../__variables.scss";

#account-planinfo {
  .subheadline {
    padding: 10px 0px;
    font-size: $text-large;
  }

  .hint {
    color: $text-hint;
    margin-bottom: 10px;
  }

  .plan-wrapper {
    .name {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .attribute-list {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 50%;
      }
    }
  }

  .error {
    text-align: center;
    color: $text-failure;
    margin-top: 25px;
  }

  @media (max-width: $breakpoint) {
    .plan-wrapper {
      .attribute-list {
        display: block;
        div {
          width: 100%;
        }
      }
    }
  }
}
